import React, { Suspense, lazy, useState } from 'react';
import { styled, useTheme } from "@mui/material/styles";
import { Box } from "@mui/material";
import Menubar from './common/Menubar';
import { Routes, Route } from "react-router-dom";
import './App.css'
import Loader from './UI/loader';
import ChatBot from './chatbot/ChatBot';
import ReplacementList from './unit/replacementList';
import WarehouseDashboard from './dashboard/WarehouseDashboard';
import MiniBot from './chatbot/MiniBot';
import Dashboard from './dashboard/Dashboard';


// Consumables
const ConsumeStock = lazy(() => import('./consumables/StockDetail'));
const ConsumeEntry = lazy(() => import('./consumables/StockEntry'));
const ConsumeExpired = lazy(() => import('./consumables/Expire'));
const ConsumeExpiring = lazy(() => import('./consumables/Expiring'));

// User Management
const CreateUser = lazy(() => import('./usermanagement/CreateUser'));
const ChangePassword = lazy(() => import('./usermanagement/Changepassword'));
const UserList = lazy(() => import('./usermanagement/userlist'));
const Role = lazy(() => import('./usermanagement/Roles'))

// Warehouse
const WarehouseList = lazy(() => import('./Warehouse/WarehouseList'));
const AddWarehouse = lazy(() => import('./Warehouse/Addwarehouse'));

// Unit
const UnitReplacement = lazy(() => import('./unit/unitReplacement'));
const UnitTracking = lazy(() => import('./unit/unitTracking'));
const UnitStatus = lazy(() => import('./unit/unitStatus'));
const FlcList = lazy(() => import('./unit/FLC/flcList'));
const Training = lazy(() => import('./unit/Training/training'));
const TrainingList = lazy(() => import('./unit/Training/trainingList'));
const CreateFLC = lazy(() => import('./unit/FLC/createFLC'));

// Order
const CreateOrder = lazy(() => import('./ordermanagement/CreateOrder'));
const OrderList = lazy(() => import('./ordermanagement/OrderList'));
const Allocation = lazy(() => import('./ordermanagement/AllocateList'));
const AllocateListTwo = lazy(() => import('./ordermanagement/AllocateListTwo'));
const SenderAllocation = lazy(() => import('./ordermanagement/Sender_Allocation'))
const ReceiverAllocation = lazy(() => import('./ordermanagement/Receiver_Allocation'))
const RaiseRequest = lazy(() => import('./ordermanagement/RaiseRequest'))
const RequestList = lazy(() => import('./ordermanagement/RequestList'))

//Issue Management
const IssueRequest = lazy(() => import('./issuemanagement/issueRequest'));
const IssueList = lazy(() => import('./issuemanagement/issueList'));

const Router = () => {
    const theme = useTheme();
    const apicallsrestrict = React.useRef(false);
    const DrawerHeader = styled("div")(({ theme }) => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    }));
    let userDetails = JSON.parse(localStorage.getItem("userMenu"));

    const [isActive, setIsActive] = useState(userDetails[0]['Menu'])
    const [isSubActive, setIsSubActive] = useState(userDetails[0]['SubMenu']);
    console.log(userDetails);
    const handleMenu = (number, subNumber) => {
        setIsActive(number);
        setIsSubActive(subNumber);
    }

    const botVisiblity = window.location.pathname === "/chatbot"

    return (

        <Box className="dark-theme">
            {!botVisiblity && <MiniBot/>}
            <Box display = {'flex'} justifyContent = {'space-between'} alignItems = {'center'} >
                <Menubar activation = {handleMenu} item = {userDetails[0]['Menu']} subitem = {userDetails[0]['SubMenu']} />
                <Box component="main" sx={{ flexGrow: 1, p: 3, overflowY:"hidden", minHeight:"100vh" }} >
                    <DrawerHeader />
                    <Suspense fallback={<center style={{marginTop: "40vh"}}><Loader/></center>}>
                    <Routes>
                        <Route path="/home" element={<Dashboard />} />
                        <Route path="/warehousedashboard" element={<WarehouseDashboard />} />
                        <Route path="/consumables/stockdetails" element={<ConsumeStock />} />
                        <Route path="/consumables/stockentry"   element={<ConsumeEntry />} />
                        <Route path="/consumables/expiredstocks" element={<ConsumeExpired />} />
                        <Route path="/consumables/expiringstocks" element={<ConsumeExpiring />} />

                        <Route path="/usermanagement/createuser" element={<CreateUser />} />
                        <Route path="/usermanagement/changepassword" element={<ChangePassword />} />
                        <Route path="/usermanagement/userlist" element={<UserList/>} />
                        <Route path="/usermanagement/roles" element={<Role />} />

                        <Route path="/evwarehouse/warehouselist" element={<WarehouseList />} />
                        <Route path="/evwarehouse/createwarehouse" element={<AddWarehouse />} />

                        <Route path="/unit/unitreplacement" element={<UnitReplacement />} />
                        <Route path="/unit/replacementlist" element={<ReplacementList />} />
                        <Route path="/unit/unittracking" element={<UnitTracking />} />
                        <Route path="/unit/unitstatus" element={<UnitStatus />} />
                        <Route path="/unit/flcList" element={<FlcList />} />
                        <Route path="/unit/training" element={<Training />} />
                        <Route path="/unit/traininglist" element={<TrainingList />} />
                        <Route path="/unit/createflc" element={<CreateFLC />} />

                        <Route path="/order/create" element={<CreateOrder />} />
                        <Route path="/order/list" element={<OrderList />} />
                        <Route path="/order/allocation" element={<Allocation />} />
                        <Route path="/order/managerallocation" element={<AllocateListTwo />} />
                        <Route path="/order/sender_allocation/:orderdata" element={<SenderAllocation />} />
                        <Route path="/order/receiver_allocation/:orderdata" element={<ReceiverAllocation />} />
                        <Route path="/order/create_request" element={<RaiseRequest />} />
                        <Route path="/order/requestlist" element={<RequestList />} />

                        <Route path="/issuemanagement/issuerequest" element={<IssueRequest />} />
                        <Route path="/issuemanagement/issuelist" element={<IssueList />} />

                        <Route path="/chatbot" element={<ChatBot />} />
                        
                    </Routes>
                    </Suspense>
                </Box>
            </Box>
        </Box>
        
    )
}

export default Router