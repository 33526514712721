import { Card, Fab, Fade, FormControl, InputAdornment, OutlinedInput, Popper, Typography } from "@mui/material";
import React from "react";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import { Box } from "@mui/system";
import { useState } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from "react";
import { selectProps } from "../assets/styles";
import VoiceAssist from "./voiceAssist";
import SendIcon from '@mui/icons-material/Send';
import CloseIcon from '@mui/icons-material/Close';
import Thinking from "./thinking";

const MiniBot = () => {

  const [showPopup, setShowPopup] = useState(false)
  const [target, setTarget] = useState(null)
  const [listening , setListening] = useState("")
  const [iamVoice , setIamVoice] = useState("")
  const [iamMessage , setIamMessage] = useState("")
  const [messageLoading, setMessageLoading] = useState(false)

  const [chat, setChat] = useState([
    {
      message: "Hi, how can i help you?", 
      bot: true, 
      sql: null
    }
  ])

  function sendMessage(){
    setMessageLoading(true)
    fetch(`${process.env.REACT_APP_API}/ImBot/IM_Bot`, {
      method: "POST",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        "textmessage": iamVoice.length> 0 ? iamVoice : iamMessage,
      }),
    }).then(res => res.json())
      .then(data => {setChat((prev)=>[...prev, {message: data['message'], bot: true , sql: data['sql']}]); setMessageLoading(false)})
  }


  const enterKey = event =>{
    if (event.key === 'Enter') {
      if(iamVoice.length> 0 ? iamVoice : iamMessage !== ''){
        setChat((prev)=>[...prev, {message: iamVoice.length> 0 ? iamVoice : iamMessage, bot: false, sql: null}])
        setIamMessage('')
        sendMessage()
      }
    }
  }



  function handleToggle(e){
    setShowPopup(event => !event); 
    setTarget(e.currentTarget)
  }

  const scrollToBottom = () => {
    const chatContainer = document.getElementById('chat-container');
    if (chatContainer) {
        chatContainer.scrollTop = chatContainer.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
    AOS.init();
},[chat])

  const submitButton = () =>{
    if(iamVoice.length> 0 ? iamVoice : iamMessage !== ''){
      setChat((prev)=>[...prev, {message: iamVoice.length> 0 ? iamVoice : iamMessage, bot: false, sql: null}])
      setIamMessage('')
      sendMessage()
    } 
}

const ChartCard = function({data}){

    const history = data.message
    const myMessages = data.bot

    const splitParagraphs = history.split('\n\n');
    const splitLine = splitParagraphs.map(value => value?.split('\n-'))

    return(
      <Box display={"flex"}>
        <Box display={"flex"} flex={1} flexDirection={ myMessages ? 'row' : 'row-reverse'}>
          <Card sx={{
                  marginBottom: 1, 
                  maxWidth: '90%',
                  flexDirection : myMessages ? 'row' : 'row-reverse',
                  bgcolor: myMessages ? "#59d69e" : "#EEEEEE", 
                  padding: "10px 15px",
                  borderRadius: myMessages ? '25px 25px 25px 0px' :'25px 25px 0px 25px'
                  }}>      
                  {
                    splitLine[0].map((paragraph , index) => (
                      <>
                      { index === 0 ? <p className='font-16 font-700'>{paragraph}</p> : 
                      <li className='font-16 font-700'>{paragraph}</li>}
                      </>
                      ))
                  }
                </Card>
        </Box>
      </Box>
  )
}

  return (
    <>
    <Box zIndex={9999} position={"fixed"} right={50} bottom={30}>
      <Fab 
      size="large" aria-label="minibot" 
      onClick={handleToggle}
      className={!showPopup ? "bg-bot" : 'bg-cLosebot'}
      >
        {!showPopup ? <SupportAgentIcon className="icon-white" /> : <CloseIcon className="icon-white"/>}
      </Fab>
    </Box>


    <Popper open={showPopup} anchorEl={target} placement={"bottom-end"} transition>
        {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
        <Box 
            data-aos="fade-up"
            marginBottom={1} 
            bgcolor={'rgba(22, 22, 34, 0.575)'} 
            border= {"1px solid #ffffff50"}
            borderRadius={1} 
            width={350} 
            height={450}
            padding={1}
            sx={{
                backdropFilter: "blur(15px)"
            }}
            >
            <Box 
            height={"89%"}
            id = "chat-container"
            sx={{
                overflowY: "auto",
                paddingRight: '6px',
                margin:" 3px",
            }}
            >
            {chat.length > 0 && chat.map((data) => <ChartCard data={data}/>)}
            {messageLoading && <Thinking/>}
            </Box>
            <Box>
                    <FormControl fullWidth size="small">
                        <OutlinedInput
                        id="input"
                        {...selectProps}
                        variant="outlined"
                        value={iamVoice.length> 0 ? iamVoice : iamMessage}
                        onChange={(e)=> setIamMessage(e.target.value)}
                        onKeyDown={enterKey}
                        placeholder={listening ? "Listening . . ." :"Replay"}
                        pattern="[A-Za-z]"
                        type="text"
                        endAdornment = {
                        <>
                            <InputAdornment>
                            <VoiceAssist 
                                    submitButton={submitButton}
                                    setListening={setListening}
                                    setIamVoice={setIamVoice}
                                    />
                            </InputAdornment>
                            <InputAdornment position="end">
                                
                                <SendIcon onClick={submitButton} className="icon-white"/>
                            </InputAdornment>
                        </>
                        }
                        ></OutlinedInput>
                    </FormControl>
                </Box>
            </Box>
            </Fade>
            )}
        </Popper>
    </>
  );
};

export default MiniBot;
